import {useLoaderData, useParams} from "react-router-dom";
import {useContext, useState} from "react";
import UserAccount from "../../../Components/UserAccount";
import {priceBuilder} from "../../../Hooks/Price"
import "../../../Assets/Css/Pages/User/Order/orderItem.css";
import {useTranslation} from "react-i18next";
import {UserIsNotLogged} from "../../../Components/UserUIMessages";
import {UserContext} from "../../../Contexts/UserContext";

function Order(){
    const loader = useLoaderData();
    const {id, type} = useParams();
    const [order, setOrder] = useState(loader);
    const {t} = useTranslation();
    const {user} = useContext(UserContext);

    if (user === null){
        return(<UserIsNotLogged />);
    }

    if (order === null){
        return(
            <></>
        );
    }

    const breadcrumb = [{name:  t('user-page.order.title'), path: '/user/orders'}, {name: (id)?.replace('-', '/'), path: null}];

    return(
        <>
            <UserAccount title={ t('user-page.order.sub-title')+' '+(id)?.replace('-', '/')} className="my-order" breadcrumb={breadcrumb} >
                <Items data={order.partialOrder} isPartial={true} />
                {order.fullOrder !== null ? <Items data={order.fullOrder} isPartial={false} /> : null}
            </UserAccount>
        </>
    );
}

/**
 * @param data
 * @param isPartial
 * @returns {JSX.Element}
 * @constructor
 */
function Items({data, isPartial}){

    const {t} = useTranslation();

    function fullOrderPartialHeader(externalNumberOrder1){
        return(
            <tr>
                <td colSpan="4" className="number-title" >
                    {t('user-page.order.external-number')}: {externalNumberOrder1}
                </td>
            </tr>
        );
    }

    function order(){
        return data.map((order, key1) => {
                return(
                    <tbody key={key1}  >
                        {isPartial === false ? fullOrderPartialHeader(order.externalNumberOrder1) : null}
                        {orderItems(order)}
                    </tbody>
                );
            });
    }

    function orderItems(order){
        return order.orderItem.map((orderItem, key2) => {
            return(
                <tr key={key2} >
                    <td className="name" >{orderItem.productName}</td>
                    <td className="quantity" >{orderItem.quantityInPackage} {orderItem.product.unit}</td>
                    <td className="unit-price" >{priceBuilder(orderItem.unitPrice, order.currencyCode)}</td>
                    <td className="total" >{priceBuilder((orderItem.unitPrice * orderItem.quantityInPackage), order.currencyCode)}</td>
                </tr>
            );
        });
    }

    function summary(orders){
        let sum = 0;

        orders.map((order) => {
            order.orderItem.map((item) => {
                sum += item.unitPrice * item.quantityInPackage;
            });
        });

        return sum;
    }

    function textForFullOrder(){
        return(
            <div className="its-full-order" >
                {t('user-page.order.full-order-title')}
            </div>
        );
    }


    return(
        <>
            {isPartial === false ? textForFullOrder() : null}

            <table className="table-data table-order" >
                <thead>
                <tr>
                    <th className="name" >{t('user-page.order.product-name')}</th>
                    <th className="quantity" >{t('user-page.order.quantity')}</th>
                    <th className="unit-price" >{t('user-page.order.unit-price')}</th>
                    <th className="total" >{t('user-page.order.total')}</th>
                </tr>
                </thead>
                {order(data)}
            </table>

            <div className="summary" >{t('user-page.order.total')}: {priceBuilder(summary(data), data[0].currencyCode)}</div>
        </>
    );
}

export default Order;
