import React from "react";
import {FieldValues, useForm} from "react-hook-form";
import {POST} from "../../../Hooks/RequestV2";
import {UseFormSetError} from "react-hook-form/dist/types/form";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";

interface fileTypeData {
    label: string,
    format: string
}

const fileTypes = {
    CsvData: {
        label: 'CSV z kropka-data faktury',
        format: 'csv-data'
    },
    CsvDot: {
        label: 'CSV z kropką',
        format: 'csv-dot'
    },
    CsvDotNoHeader: {
        label: 'CSV z kropką (bez nagłówka)',
        format: 'csv-dot-no-header'
    },
    CsvCommon: {
        label: 'CSV z przecinkiem',
        format: 'csv-common'
    },
    CsvCommonNoHeader: {
        label: 'CSV z przecinkiem (bez nagłówka)',
        format: 'csv-common-no-header'
    },
    EDIXML: {
        label: 'Edi XML',
        format: 'edi-xml'
    },
    PCMARKET: {
        label: 'PC Market',
        format: 'pc-market'
    },
    OptimaComarch: {
        label: 'Optima Comarch',
        format: 'optima-comarch'
    },
    SUBIEKT: {
        label: 'Subiekt',
        format: 'subiekt'
    },
    EAN: {
        label: 'EAN',
        format: 'ean'
    },
    HOMER: {
        label: 'Homer',
        format: 'homer'
    },
    XLS: {
        label: 'XLS',
        format: 'xls'
    },
    PDF: {
        label: 'PDF',
        format: 'pdf'
    }
};

function downloadFile(data: FieldValues, setError: UseFormSetError<FieldValues>, invoiceId: number): void {

    data.id = invoiceId;

    if (data.format === fileTypes.EDIXML.format && data.payer_code === ''){
        setError('payer_code', {message: 'Dla formatu EDI XML, musisz podać kod płatnika.'});

        return;
    } else if (data.format !== fileTypes.EDIXML.format) {
        data.payer_code = null;
    }

    function getFileName(contentDisposition: string): string | null{
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        let matches = filenameRegex.exec(contentDisposition);

        if (matches != null && matches[1]) {
            return matches[1].replace(/['"]/g, '');
        }

        return null;
    }

    POST('/user/invoice/download-file', data)
        .then((response) => {
            if (response.status === 200){
                response.blob().then((blob) => {
                    const contentDisposition = response.headers.get('Content-Disposition');
                    const fileName = getFileName(contentDisposition ?? 'faktura.csv') ?? 'faktura.csv';
                    const fileNameArray = fileName?.split('.') ?? ['faktura', 'csv'];

                    const blobWithType = new Blob([blob], { type: 'application/octet-stream' });

                    const url = URL.createObjectURL(blobWithType);
                    const a = document.createElement('a');

                    a.href = url;
                    a.download = fileNameArray[0] + '.' + fileNameArray[1];

                    document.body.appendChild(a);

                    a.click();
                    window.URL.revokeObjectURL(a.href);
                    document.body.removeChild(a);
                });
            }
        });
}

function Form({transformToCart, invoiceId}: any): React.ReactElement {
    const form      = useForm();
    const errors    = form.formState.errors;
    const {id}      = useParams();
    const {t}       = useTranslation();

    function sendRequest(data: FieldValues){
        downloadFile(data, form.setError, parseInt(id ?? '0'));
    }

    return(
        <form onSubmit={form.handleSubmit(sendRequest)} >
            <h2>Pobierz fakturę w wybranym formacie</h2>


            <div className={'field'+(errors.format ? ' error' : '')} >
                <label>Format pliku {errors.format?.message && <span>{errors.format.message.toString()}</span>}</label>
                <select {...form.register('format', {required: 'Musisz wybrać format'})} defaultValue={''} >

                    <option value={''} disabled={true} >-- Wybierz typ pliku</option>
                    {Object.values(fileTypes).map((file: fileTypeData, key: number) => {
                        return <option key={key} value={file.format} >{file.label}</option>;
                    })}
                </select>
            </div>
            {form.watch('format') === fileTypes.EDIXML.format ?

                <div className={'field'+(errors.payer_code ? ' error' : '')} >
                    <label>{t('user-page.invoice.filters.payment-code')} {errors.payer_code?.message && <span>{errors.payer_code.message.toString()}</span>}</label>
                    <input {...form.register('payer_code')} />
                </div>

                : null}

            <div className={'button'} >
                <button className={'btn teal'} type={'button'} onClick={() => transformToCart(invoiceId)} ><i className="fas fa-cart-arrow-down" ></i> {t('user-page.saved-carts.add-to-cart')}</button>
                <button className={'btn'} >{t('user-page.invoices.file-to-download')}</button>
                <button className={'btn teal'} type={'button'} onClick={() => downloadFile({format: fileTypes.XLS.format}, form.setError, parseInt(id ?? '0'))} >XLS</button>
                <button className={'btn teal'} type={'button'} onClick={() => downloadFile({format: fileTypes.PDF.format}, form.setError, parseInt(id ?? '0'))} >PDF</button>
            </div>

            <p>{t('user-page.invoice.filters.info')}</p>
        </form>
    );
}

export {Form, fileTypes};
export type { fileTypeData };
