import UserAccount from "../../../Components/UserAccount";
import {Link, useLoaderData, useNavigate, useParams} from "react-router-dom";
import {useContext, useState} from "react";
import getInvoicePaymentType from "../../../Hooks/InvoicePaymentType";
import "../../../Assets/Css/Pages/User/Invoice/invoice.css"
import {useTranslation} from "react-i18next";
import {ChannelContext} from "../../../Contexts/ChannelContext";
import {UserIsNotLogged} from "../../../Components/UserUIMessages";
import {Form} from "./DownloadFile";
import {POST} from "../../../Hooks/RequestV2";

function Invoice(){
    const loader                    = useLoaderData();
    const [data, setData]           = useState(loader);
    const {id}                      = useParams();
    const {t}                       = useTranslation();
    const {user}                    = useContext(ChannelContext);
    const navigate = useNavigate();

    if (user === null){
        return(<UserIsNotLogged />);
    }

    if (data.invoice === null){
        return(
            <></>
        );
    }

    function transformToCart(id){
        POST('/user/invoice/transform-to-cart', {invoice_id: id})
            .then(response => {
                if (response.status === 204){
                    navigate('/cart');
                }
            });
    }

    const paymentType = getInvoicePaymentType(data.invoice.paymentTypeSignature);
    const breadcrumb = [{name: t('user-page.invoices.title'), path: '/user/invoices'}, {name: data.invoice.number, path: null}];

    return(
        <UserAccount title={t('user-page.invoice.invoice')+': '+data.invoice.number} className="my-invoice" breadcrumb={breadcrumb} >

            <div className={'top-data'} >
                <div className="types" >
                    <div className="type" >
                        <span>{t('user-page.invoice.contractor')}:</span>
                        <span>{data.invoice.contractorName}</span>
                    </div>
                    <div className="type" >
                        <span>{t('user-page.invoice.receiver')}:</span>
                        <span>{data.invoice.receiverName}</span>
                    </div>
                    <div className="type" >
                        <span>{t('user-page.invoice.filters.document-type')}:</span>
                        <span>{t('user-page.invoices.types.'+data.invoice.type)}</span>
                    </div>
                    <div className="type" >
                        <span>{t('user-page.invoice.wz-numbers')}:</span>
                        <span>{data.invoice.wzsNumbers.join(', ')}</span>
                    </div>
                    <div className="type" >
                        <span>{t('user-page.invoices.date-create')}:</span>
                        <span>{data.invoice.saleDate}</span>
                    </div>
                    <div className="type" >
                        <span>{t('user-page.invoice.value')}:</span>
                        <span>{data.invoice.nettoValue} netto<br/>{data.invoice.bruttoValue} brutto</span>
                    </div>
                    <div className="type" >
                        <span>{t('user-page.invoice.payment-type')}</span>
                        <span>{paymentType}</span>
                    </div>
                    <div className="type" >
                        <span>{t('user-page.invoice.payment-date')}</span>
                        <span>{data.invoice.paymentDate}</span>
                    </div>
                    <div className="type" >
                        <span>{t('user-page.invoice.to-payment')}:</span>
                        <span>{data.invoice.toPay}</span>
                    </div>

                </div>
                <div className={'download-invoice'} >
                    <Form transformToCart={transformToCart} invoiceId={data.invoice.id} />
                </div>
            </div>

            <table className="table-data" >
                <thead>
                    <tr>
                        <th>{t('user-page.invoice.lp')}.</th>
                        <th>{t('user-page.invoice.item-name')}</th>
                        <th>{t('user-page.invoice.index')}</th>
                        <th>{t('user-page.invoice.ean')}</th>
                        <th>{t('user-page.invoice.item-quantity')}</th>
                        <th>{t('user-page.invoice.unit')}</th>
                        <th>{t('user-page.invoice.item-price-netto')}</th>
                        <th>{t('user-page.invoice.item-discount')}</th>
                        <th>{t('user-page.invoice.item-price-after-discount-netto')}</th>
                        <th>{t('user-page.invoices.value-netto')}</th>
                        <th>{t('user-page.invoice.tax')}</th>
                        <th>{t('user-page.invoice.value-vat')}</th>
                        <th>{t('user-page.invoices.value-brutto')}</th>
                    </tr>
                </thead>
                <tbody>
                    {data.invoiceItems.map((item, key) => {
                        return(
                            <tr key={key} >
                                <td>{key+1}</td>
                                <td>
                                    {item.slug !== null ?
                                        <Link to={'/product/'+item.slug} >{item.name}</Link>
                                        : item.name}
                                </td>
                                <td>{item.index}</td>
                                <td>{item.ean}</td>
                                <td>{item.quantity}</td>
                                <td>{item.unit}</td>
                                <td>{item.netto}</td>
                                <td>{item.discount} %</td>
                                <td>{item.nettoAfterDiscount}</td>
                                <td>{item.nettoValue}</td>
                                <td>{item.tax}</td>
                                <td>{item.valueTax}</td>
                                <td>{item.bruttoValue}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </UserAccount>
    );
}

export default Invoice;
