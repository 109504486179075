import {useLoaderData} from "react-router-dom";
import moment from "moment";
import {useTranslation} from "react-i18next";
import {Table} from "../../../Components/Table";
import UserAccount from "../../../Components/UserAccount";
import {GetPaymentsForReceivers} from "../../../Requests/User";
import {useContext, useState} from "react";
import {UserIsNotLogged} from "../../../Components/UserUIMessages";
import {priceBuilder} from "../../../Hooks/Price";

import "../../../Assets/Css/Pages/User/Payment/payment.css";
import {UserContext} from "../../../Contexts/UserContext";
import {getCoreRowModel, getPaginationRowModel, useReactTable} from "@tanstack/react-table";

/**
 * Base Component
 */
function Payment(){
    const loader = useLoaderData();
    const {user}                            = useContext(UserContext);
    const [payments, setPayments]           = useState(loader);
    const {receiverPayments, setReceiver}   = GetPaymentsForReceivers();

    if (user === null){
        return(<UserIsNotLogged />);
    }

    if (payments === null){
        return(
            <></>
        );
    }

    if (payments.length === 0){
        payments.payments = [];
        payments.total = 0;
        payments.receivers = [];
    }

    if (receiverPayments !== null){
        if (receiverPayments.length === 0){
            payments.payments = [];
            payments.total = 0;
        } else {
            payments.payments = receiverPayments.payments;
            payments.total = receiverPayments.total;
        }
    }

    return(<DOM payments={payments} setReceiver={setReceiver} />);
}

/**
 * DOM tree
 *
 * @param payments
 * @param setReceiver
 * @param breadcrumb
 * @returns {JSX.Element}
 * @constructor
 */
function DOM({payments, setReceiver}){
    const {t} = useTranslation();
    const breadcrumb = [{name:t('user-page.payment.title'), path: null}];
    const [stateReceiver, setStateReceiver] = useState(0);

    const table = useReactTable({
        data: payments,
        columns: tableColumns({t}),
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
    });

    function additionalBottom(){
        return(
            <div className="total" >{t('user-page.payment.total')}: {priceBuilder(payments.total, 'PLN')}</div>
        );
    }

    function setOption(e){
        setReceiver({receiver_id: e.target.value});
        setStateReceiver(e.target.value);
    }

    return(
        <>
            <UserAccount title={t('user-page.payment.title')} className="my-payments" breadcrumb={breadcrumb} >
                <select value={stateReceiver} onChange={setOption} >
                    <option disabled={true} value="0">-- {t('user-page.payment.receiver-filter')}</option>
                    {payments.receivers.map((receiver) => {
                        return(
                            <option value={receiver.id} key={receiver.id} >{receiver.name}</option>
                        );
                    })}
                </select>
                <Table table={table} additionalBottom={additionalBottom()} />
            </UserAccount>
        </>
    );
}

/**
 * @returns {[{header: *, accessorKey: string},{header: *, cell: (function({getValue: *})), accessorKey: string},{header: *, cell: (function({getValue: *})), accessorKey: string},{header: *, cell: (function({getValue: *})), accessorKey: string}]}
 */
function tableColumns({t}){
    return [
        {
            header: t('user-page.payment.document-number'),
            accessorKey: 'invoice'
        },
        {
            header: t('user-page.payment.payment-date'),
            accessorKey: 'paymentDate',
            cell: ({getValue}) => {
                const dateTime = moment(getValue());

                return(
                    <>
                        {dateTime.format('YYYY-MM-DD hh:mm:ss')}
                    </>
                );
            }
        },
        {
            header: t('user-page.payment.to-pay'),
            accessorKey: 'toPayment',
            cell: ({getValue}) => {
                return(
                    <>
                        {priceBuilder(parseFloat(getValue()), 'PLN')}
                    </>
                );
            }
        },
        {
            header: t('user-page.payment.not-pay'),
            accessorKey: 'notPaid',
            cell: ({getValue}) => {
                return(
                    <>
                        {priceBuilder(parseFloat(getValue()), 'PLN')}
                    </>
                );
            }
        }
    ];
}

export default Payment;
