import {useContext, useEffect} from "react";
import {TransferSavedCartToCurrent} from "../../../Requests/User";
import {useNavigate} from "react-router-dom";
import {CartContext} from "../../../Contexts/CartContext";
import {GET} from "../../../Hooks/RequestV2";

function useTransferSavedCartToCart(setThrobber: any){
    const cartContext                   = useContext(CartContext);
    const {response, transferSavedCart} = TransferSavedCartToCurrent();
    const navigate                      = useNavigate();

    useEffect(() => {
        if (response !== null){
            GET('/user/cart')
                .then((response) => response.json())
                .then((json) => {
                    setThrobber(false);
                    cartContext!.setCart(json);
                    navigate('/cart?saved-cart-transfer=true');
                });
        }
    }, [response]);

    function saveCartInCurrentCart(id: number){
        transferSavedCart({id: id});
        setThrobber(true);
    }

    return saveCartInCurrentCart;
}

export {useTransferSavedCartToCart}