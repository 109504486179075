import React, {useContext, useEffect, useRef, useState} from "react";
import "../../Assets/Css/global.css";
import "../../Assets/Css/Pages/LogIn/form.css";
import {useTranslation} from "react-i18next";
import {FormProvider, useForm, useFormContext} from "react-hook-form";
// @ts-ignore
import logo from "../../Assets/Images/logo.png";
import {AuthStatusAfterFormSend} from "../../Forms/LoginForm";
import {GET, POST} from "../../Hooks/RequestV2";
import {UserType} from "../../Types/UserType";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";

interface FormDataInterface {
    username: string,
    password: string
};

function Form(): React.ReactElement {
    const {t} = useTranslation();
    const {register, handleSubmit, formState: { errors }} = useForm<FormDataInterface>();
    const loggedUserForm = useForm();
    const registerURL = 'http://www.hurt.euro-trade.pl/bok/zostan_naszym_klientem';
    const [user, setUser] = useState<UserType|null>(null);
    const [status, setStatus] = useState<any>(user ? 'logged' : null);
    const [contractor, setContractor] = useState(null);
    const [saleDepartment, setSaleDepartment] = useState(null);
    const [notification, setNotification] = useState<string|null>(null);
    const [userLoginData, setUserLoginData] = useState<object>({});

    // useEffect(() => {
    //     if (user?.admin){
    //         GET('/trader/demo-contractor')
    //             .then(response => response.json())
    //             .then((demo => {
    //                 const newContractors = [demo, ...user.adminContractors];
    //                 user.adminContractors = newContractors;
    //
    //                 console.debug(user.adminContractors)
    //
    //                 setUser(user);
    //             }));
    //     }
    // }, [user]);

    function log(data: FormDataInterface): void{

        POST('/check-credentials', data)
            .then((response) => {
                if (response.status === 200){
                    setUserLoginData(data);

                    response.json().then((json) => {

                        if ((!json.admin && json.saleDepartment.length === 1) || (json.admin && json.saleDepartment.length === 1 && ((json.adminContractors.length === 1 && json.adminOtherContractors.length === 0) || (json.adminContractors.length === 0 && json.adminOtherContractors.length === 1)))){
                            POST('/check-currency-problem', {contractor_id: json.receiver.contractor.id, sale_department_id: json.salesDepartmentInfo[0].id})
                                .then((response) => {
                                    if (response.status === 204){
                                        POST('/user/login_auth', data)
                                            .then(() => {
                                                setNotification(null);

                                                if (json.admin){
                                                    let contractor;

                                                    if (json.adminContractors.length === 1){
                                                        contractor = json.adminContractors[0].id;
                                                    } else {
                                                        contractor = json.adminOtherContractors[0].id;
                                                    }

                                                    window.location.href = 'https://'+json.salesDepartmentInfo[0].hostName+'?mini-cart=true&contractor='+contractor;
                                                } else {

                                                    if (json.salesDepartmentInfo[0].hostName === window.location.hostname){

                                                        window.location.reload();

                                                        setStatus('auth');
                                                        setUser(json);

                                                        return;
                                                    }

                                                    window.location.href = 'https://'+json.salesDepartmentInfo[0].hostName+'?mini-cart=true';
                                                }


                                                setStatus('auth');
                                                setUser(json);
                                            });
                                    } else {
                                        setStatus('not_auth');
                                        setNotification('Z uwagi na podpięcie cennika walutowego nie możesz się zalogować. Skontaktuj się ze swoim opiekunem lub z administratorem.');

                                        return;
                                    }
                                });

                            return;
                        } else {
                            setUser(json);
                            setStatus('auth');

                            return;
                        }
                    });
                } else if (response.status === 400) {
                    setStatus('error');
                } else {
                    setStatus('not_auth');
                }
            });


        // POST('/user/login_auth', data)
        //     .then((result) => {
        //         if (result.status === 401){
        //             setStatus('not_auth');
        //         } else if (result.status === 204) {
        //             GET('/user')
        //                 .then((result) => result.json())
        //                 .then((json) => {
        //                     if (!json.admin && json.saleDepartment.length === 1){
        //
        //                         POST('/user/check-currency-problem', {contractor_id: json.contractor, sale_department_id: json.salesDepartmentInfo[0].id})
        //                             .then((response) => {
        //                                 if (response.status === 204){
        //                                     setNotification(null);
        //
        //                                     window.location.href = 'https://'+json.salesDepartmentInfo[0].hostName+'?mini-cart=true';
        //                                     setStatus('auth');
        //                                     setUser(json);
        //                                 } else {
        //                                     setStatus('not_auth');
        //                                     setNotification('Z uwagi na podpięcie cennika walutowego nie możesz się zalogować. Skontaktuj się ze swoim opiekunem lub z administratorem.');
        //                                 }
        //                             });
        //
        //                         return;
        //                     }
        //                 });
        //         } else if (result.status === 404){
        //             setStatus('error');
        //         }
        //     });
    }

    function submit(data: any){
        let saleDepartment: any;

        user?.salesDepartmentInfo.map((sd: any) => {
            if (sd.hostName === data.saleDepartment){
                saleDepartment = sd;
            }
        });

        POST('/check-currency-problem', {contractor_id: data.contractor, sale_department_id: saleDepartment.id})
            .then((response) => {
                if (response.status === 204){

                    POST('/user/login_auth', userLoginData)
                        .then(() => {
                            setNotification(null);

                            if (data.hasOwnProperty('contractor')){
                                localStorage.setItem('contractor', data.contractor);
                            } else {
                                localStorage.removeItem('contractor');
                            }

                            if (data.saleDepartment === window.location.hostname){
                                window.location.href = window.location.href+'?mini-cart=true&contractor='+data.contractor;
                                return;
                            }

                            window.location.href = 'https://'+data.saleDepartment+'?mini-cart=true&contractor='+data.contractor;
                        });
                } else {
                    setNotification('Z uwagi na podpięcie cennika walutowego nie możesz się zalogować. Skontaktuj się ze swoim opiekunem lub z administratorem.');
                }
            });
    }

    if (!user || (user.salesDepartmentInfo.length < 2 && ((user.adminContractors.length === 1 && user.adminOtherContractors.length === 0) || (user.adminContractors.length === 0 && user.adminOtherContractors.length === 1))) || (!user.admin && user.saleDepartment.length === 1)){
        return (
            <div className={'body'} >
                <div className="login-form" >
                    <div className="logo" >
                        <img src={logo} alt="Logo" />
                    </div>

                    <div className={'form-notification'}>
                        {notification !== null ? <div className="form-error" >{notification}</div> : null }
                        <AuthStatusAfterFormSend status={status} />
                    </div>

                    <form onSubmit={handleSubmit(log)} className={user ? 'hidden' : ''} >
                        <div className="field" >
                            <label><i className="fas fa-user" ></i>{t('layout.login-form.login')}</label>
                            {errors.username && <div className="error" >{errors.username.message}</div>}
                            <input
                                className={errors.username && 'error'}
                                {...register('username', {required: t('layout.login-form.login-error')})} />
                        </div>
                        <div className="field" >
                            <label><i className="fas fa-lock" ></i>{t('layout.login-form.password')}</label>
                            {errors.password && <div className="error" >{errors.password.message}</div>}
                            <input
                                className={errors.password && 'error'}
                                {...register('password', {required: t('layout.login-form.password-error')})} type="password" />
                        </div>

                        <div className="button" >
                            <button className="btn" >{t('layout.login-form.login-button')}</button>
                        </div>

                        {/*<div className="register" >*/}
                        {/*    <a href={registerURL} target="_blank" >*/}
                        {/*        {t('layout.login-form.register')}*/}
                        {/*    </a>*/}
                        {/*</div>*/}
                    </form>
                </div>
            </div>
        );
    }

    return (
        <div className={'body'} >
            <div className={'check-options'+(notification !== null ? ' max' : '')} >
                {notification ?
                    <div className={'error'} >{notification}</div>
                : null}
                <form onSubmit={loggedUserForm.handleSubmit(submit)} >
                    <FormProvider {...loggedUserForm} >
                        {user.saleDepartment.length > 0 ? <SaleDepartments user={user} /> : null}
                        {user.admin && user.adminContractors.length > 0 ? <Contractors user={user} /> : null}
                    </FormProvider>

                    <div className={'button'} >
                        <button className={'btn'} >Wejdź</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

function SaleDepartments({user}: {user: UserType}): React.ReactElement {
    const form = useFormContext();

    return (
        <div className={'option sale-departments'} >
            <h1>Wybierz dział</h1>

            <div className={'scrollable'} >
                {user.salesDepartmentInfo.map((saleDepartment: any, key: number) => {
                    return (
                        <div key={key} >
                            <input type={'radio'} checked={user.salesDepartmentInfo.length === 1 ? true : undefined} {...form.register('saleDepartment')} value={saleDepartment.hostName} required={true} />
                            <span>{saleDepartment.name}</span>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

function Contractors({user}: {user: UserType}): React.ReactElement {
    const form = useFormContext();

    const [myContractors, setMyContractors] = useState(user.adminContractors);
    const [otherContractors, setOtherContractors] = useState(user.adminOtherContractors);
    const [allContractors, setAllContractors] = useState([]);

    const refMyContractorsInputName = useRef(null);
    const refMyContractorsInputNumber = useRef(null);
    const refOtherContractorsInputName = useRef(null);
    const refOtherContractorsInputNumber = useRef(null);
    const refAllContractorsInputName = useRef(null);
    const refAllContractorsInputNumber = useRef(null);

    function searchContractorsInData(data: object[], nameInput: HTMLInputElement|null, numberInput: HTMLInputElement|null, setContractor: React.Dispatch<any>): void {
        const nameValue = nameInput?.value || '';
        const numberValue = numberInput?.value || '';

        const removeDiacritics = (str: string) => {
            return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        };

        const filtered = data.filter((item: any) => {
            const nameMatch = nameValue ? removeDiacritics(item.name.toLowerCase()).includes(removeDiacritics(nameValue.toLowerCase())) : false;
            const hurtIdMatch = numberValue ? String(item.hurtId).includes(numberValue) : false;

            return nameMatch || hurtIdMatch;
        });

        if (filtered.length === 0 && nameValue === '' && numberValue === ''){
            setContractor(data);
        } else {
            setContractor(filtered);
        }
    }

    function searchContractorsInDB(nameInput: HTMLInputElement|null, numberInput: HTMLInputElement|null, setContractor: React.Dispatch<any>): void {
        if (!nameInput?.value && !numberInput?.value){
            return;
        }

        POST('/trader/find/contractor', {contractor_name: nameInput?.value, contractor_number: numberInput?.value})
            .then((response) => {
                if (response.status === 200){
                    response.json().then((json) => setContractor(json));
                }
            });
    }

    return (
        <div className={'option contractors'} >
            <h1>Wybierz kontrahenta</h1>


                <Tabs>
                    <TabList>
                        <Tab><button type={'button'} className={'btn'} >Moi kontrahenci</button></Tab>
                        <Tab><button type={'button'} className={'btn'} >Lista zapasowa</button></Tab>
                        <Tab><button type={'button'} className={'btn'} >Dowolny kontrahent</button></Tab>
                    </TabList>

                    <div className={'scrollable'} >
                        <TabPanel>
                            <div className={'search'} >
                                <h3>Filtruj</h3>

                                <input
                                    name={'my-contractor-name'}
                                    placeholder={'Nazwa kontrahenta'}
                                    ref={refMyContractorsInputName}
                                    onChange={(e) => searchContractorsInData(user.adminContractors, refMyContractorsInputName.current, refMyContractorsInputNumber.current, setMyContractors)} />
                                <input
                                    name={'my-contractor-number'}
                                    placeholder={'Numer kontrahenta'}
                                    ref={refMyContractorsInputNumber}
                                    onChange={(e) => searchContractorsInData(user.adminContractors, refMyContractorsInputName.current, refMyContractorsInputNumber.current, setMyContractors)}  />
                            </div>
                            {myContractors.length === 0 ?

                                <div className={'empty'} >Brak danych</div>

                                :
                                <>
                                    {myContractors.map((contractor: any, key: number) => {
                                        return (
                                            <div key={key} >
                                                <input type={'radio'} checked={myContractors.length === 1 ? true : undefined} {...form.register('contractor')} value={contractor.id} required={true} />
                                                <span>[{contractor.hurtId}] {contractor.name}</span>
                                            </div>
                                        );
                                    })}
                                </>
                            }

                        </TabPanel>
                        <TabPanel>
                            {user.adminOtherContractors.length === 0 ?

                                <div className={'empty'} >Brak danych</div>

                                :
                                <>
                                    <div className={'search'} >
                                        <h3>Filtruj</h3>

                                        <input
                                            name={'my-contractor-name'}
                                            placeholder={'Nazwa kontrahenta'}
                                            ref={refOtherContractorsInputName}
                                            onChange={(e) => searchContractorsInData(user.adminOtherContractors, refOtherContractorsInputName.current, refOtherContractorsInputNumber.current, setOtherContractors)} />
                                        <input
                                            name={'my-contractor-number'}
                                            placeholder={'Numer kontrahenta'}
                                            ref={refOtherContractorsInputNumber}
                                            onChange={(e) => searchContractorsInData(user.adminOtherContractors, refOtherContractorsInputName.current, refOtherContractorsInputNumber.current, setOtherContractors)}  />
                                    </div>
                                    {otherContractors.map((contractor: any, key: number) => {
                                        return (
                                            <div key={key} >
                                                <input type={'radio'} {...form.register('contractor')} value={contractor.id} required={true} />
                                                <span>[{contractor.hurtId}] {contractor.name}</span>
                                            </div>
                                        );
                                    })}
                                </>
                            }
                        </TabPanel>
                        <TabPanel>
                            <div className={'search'} >
                                <h3>Szukaj</h3>

                                <input name={'my-contractor-name'} placeholder={'Nazwa kontrahenta'} ref={refAllContractorsInputName} />
                                <input name={'my-contractor-number'} placeholder={'Numer kontrahenta'} ref={refAllContractorsInputNumber} />

                                <button className={'btn'} type={'button'} onClick={(e) =>  searchContractorsInDB(refAllContractorsInputName.current, refAllContractorsInputNumber.current, setAllContractors)}>Szukaj</button>
                            </div>
                            {allContractors.length === 0 ?

                                <div className={'empty'} >Brak danych</div>

                                :
                                <>
                                    {allContractors.map((contractor: any, key: number) => {
                                        return (
                                            <div key={key} >
                                                <input type={'radio'} {...form.register('contractor')} value={contractor.id} required={true} />
                                                <span>[{contractor.hurtId}] {contractor.name}</span>
                                            </div>
                                        );
                                    })}
                                </>
                            }
                        </TabPanel>
                    </div>
                </Tabs>
        </div>
    );
}

export {Form as PageLogin};