import React, {useContext, useEffect, useRef, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import UserAccount from "../../../Components/UserAccount";
import {Table} from "../../../Components/Table";
import moment from "moment";
import {useTranslation} from "react-i18next";
import {ChannelContext} from "../../../Contexts/ChannelContext";
import {UserIsNotLogged} from "../../../Components/UserUIMessages";
import {Filters, Search} from "./Filters/Filters";
import {POST} from "../../../Hooks/RequestV2";
import {FormProvider, useForm} from "react-hook-form";
import {ListOptions} from "./ListOptions";
import {getCoreRowModel, getPaginationRowModel, useReactTable} from "@tanstack/react-table";
import {fileTypes} from "./DownloadFile";

function Invoices(){
    const [invoices, setInvoices] = useState([]);
    const {t}                     = useTranslation();
    const {user}                  = useContext(ChannelContext);
    const filterForm              = useForm();
    const searchForm              = useForm();
    const [fileFormat, setFileFormat] = useState();
    const [payerCode, setPayerCode] = useState();
    const [error, setError] = useState({});
    const navigate = useNavigate();

    function filterRequest(){
        POST('/user/invoices/filters', filterForm.watch())
            .then(response => response.json())
            .then(json => setInvoices(json));
    }

    function searchRequest(){
        POST('/user/invoices/search', searchForm.watch())
            .then(response => {
                if (response.status === 200){
                    return response.json();
                }
            })
            .then(json => setInvoices(json));
    }

    function transformToCart(id){
        POST('/user/invoice/transform-to-cart', {invoice_id: id})
            .then(response => {
                if (response.status === 204){
                    navigate('/cart');
                }
            });
    }

    useEffect(() => {
        filterRequest();
    }, []);

    const columns = [
        {
            header: t('user-page.invoices.date-create'),
            accessorKey: 'saleDate',
            cell: ({getValue}) => {
                const dateTime = moment(getValue());

                return(
                    <>
                        {dateTime.format('YYYY-MM-DD')}
                    </>
                );
            }
        },
        {
            header: t('user-page.invoices.invoice-number'),
            accessorKey: 'number'
        },
        {
            header: t('user-page.invoices.payment-date'),
            accessorKey: 'paymentDate'
        },
        {
            header: t('user-page.invoices.type'),
            accessorKey: 'type',
            cell: ({getValue}) => {
                return t('user-page.invoices.types.'+getValue());
            }
        },
        {
            header: t('user-page.invoices.value-netto'),
            accessorKey: 'nettoValue'
        },
        {
            header: t('user-page.invoices.value-brutto'),
            accessorKey: 'bruttoValue'
        },
        {
            header: t('user-page.invoices.options'),
            accessorKey: 'id',
            cell: ({getValue}) => {

                return(
                    <div className={'options'}>
                        {error.hasOwnProperty(getValue()) && error[getValue()].error !== null ? <div className={'error'} >{error[getValue()].error}</div> : null}
                        <div>
                            <Link to={'/user/invoice/'+getValue()} >
                                <button className="btn teal" >
                                    <i className="fas fa-file-invoice" ></i> {t('user-page.invoices.details')}
                                </button>
                            </Link>

                            <button type={'button'} className={'btn teal'} onClick={() => downloadFile({id: getValue(), format: fileFormat, payer_code: payerCode}, setError)} ><i className="fas fa-file" /> {t('user-page.invoices.file-to-download')}</button>
                        </div>
                        <div>
                            <button type={'button'} className={'btn teal'} onClick={() => downloadFile({id: getValue(), format: 'xls', payer_code: payerCode}, setError)} ><i className="fas fa-file-excel" /> XLS</button>
                            <button type={'button'} className={'btn teal'} onClick={() => downloadFile({id: getValue(), format: 'pdf', payer_code: payerCode}, setError)} ><i className="fas fa-file-pdf" /> PDF</button>
                            <button type={'button'} className={'btn teal'} onClick={() => transformToCart(getValue())} ><i className="fas fa-cart-arrow-down" ></i> {t('user-page.saved-carts.add-to-cart')}</button>
                        </div>
                    </div>
                );
            }
        }
    ];

    const table = useReactTable({
        data: invoices,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
    });

    if (user === null){
        return(<UserIsNotLogged />);
    }

    if (invoices === null){
        return (<></>);
    }

    const breadcrumb = [{name: t('user-page.invoices.title'), path: null}];

    return(
        <>
            <UserAccount title={t('user-page.invoices.title')} className="my-invoices" breadcrumb={breadcrumb} >
                <div className={'filters'} >
                    <FormProvider {...searchForm} >
                        <Search onSubmit={searchRequest} />
                    </FormProvider>
                    <div className={'or'} >{t('user-page.invoices.or')}</div>
                    <FormProvider {...filterForm} >
                        <Filters onSubmit={filterRequest} />
                    </FormProvider>
                </div>


                {
                    invoices && invoices.length > 0 ?
                    <>
                        <ListOptions
                            table={table}
                            rowQuantity={invoices.length}
                            setFileFormat={setFileFormat}
                            fileFormat={fileFormat}
                            payerCode={setPayerCode}
                            filterRequest={filterRequest}/>
                        <Table table={table} />
                    </>
                    :
                    <div className={'empty'} >{t('user-page.invoice.filters.empty-list')}</div>
                }
            </UserAccount>
        </>
    );
}

function downloadFile(data, setError) {

    if (data.format === undefined){
        setError((prev) => {
            return {
                ...prev,
                [data.id]: {
                    error: 'Musisz podać format pliku (pole wyboru znajduje się u góry listy)'
                }
            };
        });

        return;
    }

    if (data.format === fileTypes.EDIXML.format && data.payer_code === ''){

        setError((prev) => {
            return {
                ...prev,
                [data.id]: {
                    error: 'Dla formatu EDI XML, musisz podać kod płatnika.'
                }
            };
        });

        return;
    } else if (data.format !== fileTypes.EDIXML.format) {
        data.payer_code = null;
    }

    function getFileName(contentDisposition) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        let matches = filenameRegex.exec(contentDisposition);

        if (matches != null && matches[1]) {
            return matches[1].replace(/['"]/g, '');
        }

        return null;
    }

    POST('/user/invoice/download-file', data)
        .then((response) => {
            if (response.status === 200){
                response.blob().then((blob) => {
                    const contentDisposition = response.headers.get('Content-Disposition');
                    const fileName = getFileName(contentDisposition ?? 'faktura.csv') ?? 'faktura.csv';
                    const fileNameArray = fileName?.split('.') ?? ['faktura', 'csv'];

                    const blobWithType = new Blob([blob], { type: 'application/octet-stream' });

                    const url = URL.createObjectURL(blobWithType);
                    const a = document.createElement('a');

                    a.href = url;
                    a.download = fileNameArray[0] + '.' + fileNameArray[1];

                    document.body.appendChild(a);

                    a.click();
                    window.URL.revokeObjectURL(a.href);
                    document.body.removeChild(a);
                });
            }
        });
}

export default Invoices;
