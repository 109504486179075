import {RemoveSavedCart, TransferSavedCartToCurrent} from "../../../Requests/User";
import {Link, useLoaderData, useNavigate} from "react-router-dom";
import UserAccount from "../../../Components/UserAccount";
import {Table} from "../../../Components/Table";
import {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import "../../../Assets/Css/Pages/User/SavedCart/savedCarts.css"
import {UserIsNotLogged} from "../../../Components/UserUIMessages";
import {UserContext} from "../../../Contexts/UserContext";
import {GET} from "../../../Hooks/RequestV2";
import {useTransferSavedCartToCart} from "./TransferSavedCartToCart";
import {getCoreRowModel, getPaginationRowModel, useReactTable} from "@tanstack/react-table";
import {ListOptions} from "../Invoices/ListOptions";
import {SimpleModal, useSimpleModalState} from "../../../Components/SimpleModal";
import {CartContext} from "../../../Contexts/CartContext";

function SavedCarts(){
    const loader = useLoaderData();
    const [savedCarts, setSavedCarts] = useState(loader);
    const {t}                         = useTranslation();
    const {removeSavedCartRequest, removeSavedCartResponse} = RemoveSavedCart();
    const {user}                      = useContext(UserContext);
    const [throbber, setThrobber]     = useState(false);
    const saveCart                    = useTransferSavedCartToCart(setThrobber);

    useEffect(() => {
        GET('/user/saved-carts')
            .then((response) => response.json())
            .then((json) => setSavedCarts(json));
    }, [removeSavedCartResponse]);

    function remove(id){
        removeSavedCartRequest({saved_cart_id: id});
    }

    const columns = [
        {
            header: t('user-page.saved-carts.name'),
            accessorKey: 'name'
        },
        {
            header: t('user-page.saved-carts.options'),
            accessorKey: 'id',
            cell: ({getValue}) => {
                return <OptionsComponent getValue={getValue} saveCart={saveCart} remove={remove} />
            }
        }
    ];

    const table = useReactTable({
        data: savedCarts,
        columns,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
    });

    if (user === null){
        return (
            <UserIsNotLogged />
        );
    }

    if (savedCarts === null){
        return (<></>);
    }

    const breadcrumb = [{name: t('user-page.saved-carts.title'), path: null}];

    return(
        <>
            <UserAccount title={t('user-page.saved-carts.title')} className="my-saved-carts" breadcrumb={breadcrumb} >
                <Table table={table} />
            </UserAccount>
        </>
    );
}

function OptionsComponent({getValue, saveCart, remove}) {
    const {t} = useTranslation();
    const {visibility, setVisibility, toggleVisibleModal} = useSimpleModalState()
    const {cart} = useContext(CartContext);

    function enabledModal(){
        if (cart.products.length !== 0){
            setVisibility(true);
        } else {
            saveCart(getValue());
        }
    }

    return(
        <>
            <Link to={'/user/saved-cart/'+getValue()} >
                <button className="btn teal" >
                    <i className="fas fa-list" ></i> {t('user-page.saved-carts.detail')}
                </button>
            </Link>
            <button className="btn green" onClick={() => enabledModal()} >
                <i className="fas fa-cart-arrow-down" ></i> {t('user-page.saved-carts.add-to-cart')}
            </button>
            <button className="btn red" onClick={() => remove(getValue())} >
                <i className="fas fa-times"></i> {t('cart.remove')}
            </button>
            <SimpleModal className={'add-to-cart-modal'} hidden={toggleVisibleModal} visibility={visibility} wait={true} >
                <p>{t('user-page.saved-carts.add-to-cart-modal-text')}</p>

                <div className={'buttons'} >
                    <button type={'button'} className={'btn red'} onClick={() => setVisibility(false)} >{t('user-page.saved-carts.add-to-cart-modal-no')}</button>
                    <button type={'button'} className={'btn green'} onClick={() => { setVisibility(false); saveCart(getValue()); }} >{t('user-page.saved-carts.add-to-cart-modal-yes')}</button>
                </div>
            </SimpleModal>
        </>
    );
}

export default SavedCarts;
