import {ChannelContext} from "../../../Contexts/ChannelContext";
import {useProductData} from "../ProductData";
import React, {useContext, useEffect, useRef} from "react";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useAdd2Cart} from "../../../Requests/Cart";
import {CartContext} from "../../../Contexts/CartContext";
import {UserContext} from "../../../Contexts/UserContext";
import {ProductsListContext} from "../../../Contexts/ProductsListContext";
import {ListItemHelper} from "../ListItemHelper";
import {SimpleModal, useSimpleModalState} from "../../../Components/SimpleModal";
import {QuantityFields} from "../Components/QuantityFields";
import {IconsDesignation} from "../IconsDesignation";
import {QuantityDisposition} from "../Components/QuantityDisposition";

function TabularList({remove, setSummaryInfo}){
    const {products, setProducts} = useContext(ProductsListContext);
    const {t} = useTranslation();

    return(
        <div className="tabular">
            <div className="header" >
                <div className="thumb" >{t('products-list.thumb')}</div>
                <div className="name" >{t('products-list.name')}<br/>{t('products-list.index-et')} | {t('products-list.ean')}<br/>{t('products-list.producer')}</div>

                <div className="before-discount" >{t('products-list.before-discount')}</div>
                <div className="discount" >{t('products-list.discount')}</div>
                <div className="price" >{t('products-list.price-after-discount')}</div>

                <div className="quantity" >{t('products-list.avail')}</div>
                <div className="package" >{t('products-list.method-packing')}</div>
                <div className="sale" >{t('products-list.quantity')}</div>
            </div>
            {products.map((product) => {
                return <Item data={product} key={product.id} remove={remove} setSummaryInfo={setSummaryInfo} />
            })}
        </div>
    );
}

function Item({data, remove, setSummaryInfo}){
    const listItemHelper        = new ListItemHelper();
    const {cart, setCart}       = useContext(CartContext);
    const {setUser, user}       = useContext(UserContext);
    const {products, setProducts} = useContext(ProductsListContext);
    const channelContext        = useContext(ChannelContext);
    const product               = useProductData(data);
    const {t}                   = useTranslation();
    const ref                   = useRef(null);
    const {visibility, toggleVisibleModal, setVisibility} = useSimpleModalState();

    const iconsDesignation      = new IconsDesignation(product, t);

    /** New Logic add to cart and calculated */
    const {response, setBody} = useAdd2Cart();

    useEffect(() => {
        if (response !== null){
            // setCart(response);
            if (setSummaryInfo) {
                setSummaryInfo(response);
            }
        }
    }, [response]);


    function displayPrice() {
        if (!user && channelContext?.displayPriceNotLogged === true){
            return t('products-list.disable-price-not-logged')
        }

        if (user && channelContext?.displayPriceLogged === true){
            return t('products-list.disable-price-logged')
        }

        return (
            <>
                <div className="netto" >{product.priceNetto > 0 ? product.priceNetto.toFixed(2) : product.priceBefore.toFixed(2)} {t('products-list.before-tax')}</div>
                <div className="brutto" >{product.priceBrutto > 0 ? product.priceBrutto.toFixed(2) : product.priceBeforeBrutto.toFixed(2)} {t('products-list.after-tax')}</div>
            </>
        );
    }

    function displayQuantity() {
        if (!user && channelContext?.displayQuantityNotLogged === true){
            return t('products-list.disable-quantity-not-logged')
        }

        if (user && channelContext?.displayQuantityLogged === true){
            return t('products-list.disable-quantity-logged')
        }

        return <QuantityDisposition quantity={product.quantity} unit={product.unit} />;
    }

    return(
        <>
            <SimpleModal visibility={visibility} hidden={toggleVisibleModal} className={'product-thumb-modal'} >
                <img src={product.bigThumb} className={'thumb-img'} />
            </SimpleModal>

            <div className="product" ref={ref} >
                <div className={'thumb-container'} >
                    <div className="thumb" style={{backgroundImage: 'url('+product.thumb+')'}} onClick={() => toggleVisibleModal()} >
                        <i className="fas fa-search-plus more" />
                        {iconsDesignation.getIcons()}
                    </div>
                </div>

                <div className="name" >
                    <Link to={'/product/'+product.slug} >
                        <h2>{product.name}</h2>
                    </Link>
                    <div className="index" >{t('products-list.index-et')} {product.indexHurt} | {t('products-list.ean')} {product.ean}</div>
                    <div className="producer" >{t('products-list.producer')} {product.producerName}</div>
                </div>

                <div className="before-discount" >
                    {(!user && channelContext?.displayPriceNotLogged === true) || (user && channelContext?.displayPriceLogged === true) ? null :
                        <>
                            <div className="netto" >{product.priceBefore.toFixed(2)} {t('products-list.before-tax')}</div>
                            <div className="brutto" >{product.priceBeforeBrutto.toFixed(2)} {t('products-list.after-tax')}</div>
                        </>
                    }
                </div>

                <div className="discount" >
                    {(!user && channelContext?.displayPriceNotLogged === true) || (user && channelContext?.displayPriceLogged === true) ? null :
                        <>
                            {parseInt(product.discount)}%
                        </>
                    }
                </div>

                <div className="price" >
                    {displayPrice()}
                </div>

                <div className="quantity" >
                    {displayQuantity()}
                </div>
                <div className="package" >
                    {(!user && channelContext?.displayQuantityNotLogged === true) || (user && channelContext?.displayQuantityLogged === true) ? null :
                        <>{ product.quantityPackage}</>
                    }
                </div>
                <div className="sale" >

                    {user && channelContext?.displayPriceLogged === true ? null :
                        <div className="add-to-cart" >
                            <QuantityFields data={{product, products, setProducts, setBody}} label={false} />

                            {typeof remove === 'function' ?

                                <button className="btn red item-remove-button" onClick={() => remove(product.id, ref)}  >
                                    <i className="fas fa-times" ></i> Usuń
                                </button>

                                : null}
                        </div>
                    }
                </div>
            </div>
        </>
    );
}

export default TabularList;
